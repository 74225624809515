import { NotificationsManager } from '@msdyn365-commerce-modules/notifications-core';
import { getTelemetryObject, Heading, INodeProps, IPopupProps, Popup } from '@msdyn365-commerce-modules/utilities';
import { AddToCartBehavior, IAddToCartFailureResult, IAddToCartResources, ItemSuccessfullyAddedToCartNotification, PriceComponent } from '@msdyn365-commerce/components';
import { getUrlSync, IImageSettings } from '@msdyn365-commerce/core';
import { DeliveryOption, FeatureState, OrgUnitLocation, ProductDimension } from '@msdyn365-commerce/retail-proxy';
import * as React from 'react';
import { IBuyboxCallbacks, IBuyboxState } from '../../../common/buyboxInterface';
import { IBuyboxData } from '../buybox.data';
import { IBuyboxProps } from '../buybox.props.autogenerated';

import { BuyboxErrorBlock } from '../../../common/buybox-components';
import { getConfigureErrors, getGenericError } from '../../../common/utilities/error-utilities';

import { ICartActionResult } from '@msdyn365-commerce/global-state';

export interface IFindInStoreFailureResult {
    missingDimensions?: ProductDimension[];
}

export interface IBuyboxFindInStoreViewProps {
    storeSelector?: React.ReactNode;
    heading?: React.ReactNode;
    description?: React.ReactNode;
    errors?: React.ReactNode;
    button?: React.ReactNode;
    productPickupOptionList?: React.ReactNode;

    modal?: React.ReactNode;

    ContainerProps: INodeProps;

    openFindInStoreDialog(): Promise<void>;
}

// tslint:disable-next-line:max-func-body-length
export function getBuyboxFindInStore(props: IBuyboxProps<IBuyboxData>, state: IBuyboxState, callbacks: IBuyboxCallbacks): IBuyboxFindInStoreViewProps | undefined {
    const {
        data,
        slots: {
            storeSelector
        },
        resources: {
            priceFree,
            originalPriceText,
            currentPriceText,
            findInStoreHeaderText,
            findInStoreDescriptionText,
            findInStoreLinkText,
            buyBoxGoToCartText,
            buyBoxContinueShoppingText,
            buyBoxSingleItemText,
            buyBoxMultiLineItemFormatText,
            buyBoxHeaderMessageText,
            descriptionTextToShowAllPickupOptions
        },
        context: {
            request: {
                channel: {
                    PickupDeliveryModeCode
                } = { PickupDeliveryModeCode: undefined }
            }
        }
    } = props;

    const multiplePickupStoreSwitchName = 'Dynamics.AX.Application.RetailMultiplePickupDeliveryModeFeature';
    const product = data?.product?.result;
    const price = data?.productPrice?.result;
    const storeSelectorStateManager = data?.storeSelectorStateManager?.result;
    const cart = data?.cart?.result;
    const deliveryOptions = data?.deliveryOptions?.result;
    const retailMulitplePickupMFeatureState = data?.featureState?.result?.
        find((featureState: FeatureState) => featureState.Name === multiplePickupStoreSwitchName)?.IsEnabled;

    const
        {
            errorState: {
                configureErrors,
                quantityError,
                otherError,
                errorHost
            },
            modalOpen,
            quantity
        } = state;

    const onClick = () => findInStoreOnClick(props, state, callbacks);

    if (!product || !storeSelectorStateManager || storeSelector.length === 0) {
        return undefined;
    }

    // If no delivery options present on the product, or none of the delivery options
    // match the PickupDeliveryModeCode, that means the item cannot be used for BOPIS
    if (!deliveryOptions ||
        !deliveryOptions.DeliveryOptions ||
        !PickupDeliveryModeCode ||
        !deliveryOptions.DeliveryOptions.find((option: DeliveryOption) => option.Code === PickupDeliveryModeCode)) {
        return undefined;
    }

    const dialogStrings = {
        goToCartText: buyBoxGoToCartText,
        continueShoppingText: buyBoxContinueShoppingText,
        headerItemOneText: buyBoxSingleItemText,
        headerItemFormatText: buyBoxMultiLineItemFormatText,
        headerMessageText: buyBoxHeaderMessageText,
        freePriceText: priceFree,
        originalPriceText: originalPriceText,
        currentPriceText: currentPriceText
    };

    const priceComponent = price ? (
        <PriceComponent
            data={{ price: price }}
            context={props.context}
            id={props.id}
            typeName={props.typeName}
            freePriceText={dialogStrings.freePriceText}
            originalPriceText={dialogStrings.originalPriceText}
            currentPriceText={dialogStrings.currentPriceText}
        />) : '';

    const defaultImageSettings: IImageSettings = {
        viewports: {
            xs: { q: `w=240&h=240&m=6`, w: 0, h: 0 },
            lg: { q: `w=240&h=240&m=6`, w: 0, h: 0 },
            xl: { q: `w=240&h=240&m=6`, w: 0, h: 0 }
        },
        lazyload: true
    };

    const popupProps: IPopupProps = {
        context: props.context,
        className: 'ms-buybox',
        id: props.id,
        typeName: props.typeName,
        data: { product: props.data.product?.result, price: props.data.productPrice?.result },
        dialogStrings: dialogStrings,
        imageSettings: defaultImageSettings,
        gridSettings: props.context.request.gridSettings,
        productQuantity: quantity,
        priceComponent: priceComponent,
        navigationUrl: getUrlSync('cart', props.context.actionContext),
        modalOpen: modalOpen,
        setModalOpen: callbacks.changeModalOpen
    };
    const renderModalPopup = <Popup {...popupProps} />;

    return {
        ContainerProps: {
            className: 'ms-buybox__find-in-store'
        },

        storeSelector: storeSelector[0],

        openFindInStoreDialog: onClick,

        heading: (
            <Heading
                className='ms-buybox__find-in-store-heading'
                headingTag='h4'
                text={findInStoreHeaderText}
            />
        ),

        description: (
            <p className='ms-buybox__find-in-store-description'>
                {findInStoreDescriptionText}
            </p>
        ),
        productPickupOptionList: retailMulitplePickupMFeatureState ? (
            <React.Fragment>
                <p className='ms-buybox__find-in-store-description'>
                    {descriptionTextToShowAllPickupOptions}
                </p>
                <ul className={`ms-buybox__find-in-store-pickup`} aria-label={descriptionTextToShowAllPickupOptions}>
                    {deliveryOptions.DeliveryOptions.map((item: DeliveryOption) => {
                        return <li className={`ms-buybox__find-in-store-pickup-option`} aria-label={item.Description} key={item.Code}>{item.Description}</li>;
                    })
                    }
                </ul>
            </React.Fragment>
        ) : null,
        errors: (
            <BuyboxErrorBlock
                configureErrors={configureErrors}
                quantityError={quantityError}
                otherError={otherError}
                resources={props.resources}
                showError={errorHost === 'FINDINSTORE'}
            />
        ),

        button: (
            <button
                className='ms-buybox__find-in-store-button'
                onClick={onClick}
                color='secondary'
                aria-label={findInStoreLinkText}
                disabled={cart === undefined}
            >
                {findInStoreLinkText}
            </button>
        ),

        modal: renderModalPopup
    };
}

// tslint:disable-next-line: max-func-body-length
async function findInStoreOnClick(props: IBuyboxProps<IBuyboxData>, state: IBuyboxState, callbacks: IBuyboxCallbacks): Promise<void> {
    const {
        data: {
            storeSelectorStateManager: { result: storeSelectorStateManager },
            cart: { result: cart },
            productAvailableQuantity: { result: productAvailableQuantity }
        },
        resources,
        context,
        modules,
    } = props;

    const {
        selectedProduct,
        quantity
    } = state;

    let dataProduct = props.data.product.result;
    const multiplePickupStoreSwitchName = 'Dynamics.AX.Application.RetailMultiplePickupDeliveryModeFeature';
    const retailMulitplePickupMFeatureState = props.data?.featureState?.result?.
        find((featureState: FeatureState) => featureState.Name === multiplePickupStoreSwitchName)?.IsEnabled;

    if (selectedProduct) {
        dataProduct = (await selectedProduct) || dataProduct;
    }

    if (!dataProduct || !storeSelectorStateManager) {
        return;
    }

    const product = dataProduct;

    const missingDimensions = product.Dimensions && product.Dimensions.filter((dimension: ProductDimension) => !(dimension.DimensionValue && dimension.DimensionValue.Value));

    let storeSelectorId: string = '';
    if (modules && Object.keys(modules).length > 0 && modules.storeSelector && modules.storeSelector.length > 0) {
        storeSelectorId = modules.storeSelector[0].id;
    }

    if (missingDimensions && missingDimensions.length > 0) {
        if (callbacks.updateErrorState) {
            callbacks.updateErrorState(
                {
                    errorHost: 'FINDINSTORE',
                    configureErrors: getConfigureErrors(missingDimensions, resources),
                }
            );
        }
    } else {
        storeSelectorStateManager.openDialog({
            product,
            id: storeSelectorId,
            deliveryOptions: retailMulitplePickupMFeatureState ? props.data.deliveryOptions.result : undefined,
            onLocationSelected: (orgUnitLocation: OrgUnitLocation, deliveryMode: string) => {
                if (!cart) {
                    return Promise.resolve();
                }

                const behavior = props.context?.app?.config?.addToCartBehavior;

                return cart.addProductToCart({ product: product, count: quantity, location: orgUnitLocation, deliveryMode: deliveryMode })
                    .then((result: ICartActionResult) => {
                        if (result.status === 'FAILED' && result.substatus === 'MAXQUANTITY') {
                            callbacks.changeModalOpen(false);

                            const productAvailability = state.productAvailableQuantity ?
                                state.productAvailableQuantity.ProductAvailableQuantity :
                                productAvailableQuantity && productAvailableQuantity.length ?
                                    productAvailableQuantity[0].ProductAvailableQuantity : undefined;

                            const failureResult: IAddToCartFailureResult = { failureReason: 'CARTACTIONFAILED', cartActionResult: { status: result.status, substatus: result.substatus } };
                            callbacks.updateErrorState({
                                errorHost: 'ADDTOCART',
                                configureErrors: {},
                                otherError: getGenericError(failureResult, cart, resources, context, product, productAvailability, orgUnitLocation)
                            });
                        } else {
                            callbacks.updateErrorState({
                                configureErrors: {}
                            });
                            const navigationUrl = getUrlSync('cart', context.actionContext);
                            if ((behavior === undefined || behavior === AddToCartBehavior.goToCart)) {
                                if (result.status === 'SUCCESS' && navigationUrl) {
                                    window.location.assign(navigationUrl);
                                }
                            } else if (behavior === AddToCartBehavior.showModal) {
                                callbacks.changeModalOpen(true);
                            } else if (behavior === AddToCartBehavior.showNotification) {
                                const defaultImageSettings: IImageSettings = {
                                    viewports: {
                                        xs: { q: `w=240&h=240&m=6`, w: 0, h: 0 },
                                        lg: { q: `w=240&h=240&m=6`, w: 0, h: 0 },
                                        xl: { q: `w=240&h=240&m=6`, w: 0, h: 0 }
                                    },
                                    lazyload: true
                                };
                                const telemetryContent = getTelemetryObject(props.context.request.telemetryPageName!, props.friendlyName, props.telemetry);
                                const dialogStrings: IAddToCartResources = {
                                    goToCartText: resources.buyBoxGoToCartText,
                                    continueShoppingText: resources.buyBoxContinueShoppingText,
                                    headerItemOneText: resources.buyBoxSingleItemText,
                                    headerItemFormatText: resources.buyBoxMultiLineItemFormatText,
                                    headerMessageText: resources.buyBoxHeaderMessageText,
                                    freePriceText: resources.priceFree,
                                    originalPriceText: resources.originalPriceText,
                                    currentPriceText: resources.currentPriceText,
                                    addedQuantityText: resources.addedQuantityText
                                };
                                const notification = new ItemSuccessfullyAddedToCartNotification(
                                    props.context,
                                    dialogStrings,
                                    defaultImageSettings,
                                    props.context.request.gridSettings,
                                    product,
                                    props.data.productPrice?.result,
                                    quantity,
                                    navigationUrl,
                                    telemetryContent,
                                    props.id,
                                    props.typeName
                                );
                                NotificationsManager.instance().addNotification(notification);
                            }
                        }
                    }).catch((error: Error) => {
                        if (props.telemetry) {
                            props.telemetry.exception(error);
                            props.telemetry.debug('Unable to add product to cart');
                        }
                        return;
                    });
            }
        }).catch((error: Error) => {
            if (props.telemetry) {
                props.telemetry.error(error.message);
                props.telemetry.debug('Unable to find in store');
            }
            return;
        });
    }

    return;
}